.down-container {
    // position: relative;

    height: 540px;

    @media screen and (max-width: 768px) {
        height: 380/320 * 100vw;
    }

    .background-image {
        // position: absolute;
        // z-index: -1;
        // top: 0;
        // left: 0;

        // width: 100%;
        // height: 100%;

        // object-fit: cover;
    }
}
