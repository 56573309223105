.mainWrap {
    width: 100%;
    height: auto;
    .main {
        width: 60vw;
        max-width: 1500px;

        height: auto;
        margin: 0 auto;
        background: #fff;
        padding: 3.2vw 5.4vw 0;
        @media screen and (max-width: 768px) {
            width: 100vw;
            max-width: 100vw;
            padding: 7.2vw 5vw 0;
        }
        img {
            width: 100%;
            height: auto;
            display: block;
        }
        h3 {
            line-height: 1.5;
            color: #6e3d97;
            font-size: 1.8rem;
            word-spacing: 0.2vw;
            font-weight: 600;
            @media screen and (max-width: 768px) {
                font-size: 1rem;
            }
        }
        .p {
            margin: 0;
            margin-top: 1.4rem;
            font-weight: 300;
            color: #6e3d97;
            word-spacing: 0.2vw;
            font-size: 1.2rem;
            sup {
                font-size: 0.8rem;
            }
            @media screen and (max-width: 768px) {
                font-size: 1rem;
                margin-top: 1rem;
            }
        }
    }
}
