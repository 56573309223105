.game-detail-content-1-view {
    height: auto;
    // background: linear-gradient(360deg, #eef1f9 0%, #ffffff 23%, #ffffff 100%);

    background-color: #fff;

    .content {
        display: flex;
        flex-direction: row;
        justify-content: center;

        padding-top: 168px;
        padding-bottom: 128px;

        @media screen and (max-width: 768px) {
            align-items: center;
            flex-direction: column;

            padding-top: 40/320 * 100vw;
        }

        .left {
            width: 850px;
            margin-right: 36px;

            @media screen and (max-width: 768px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 6/320 * 100vw;
            }

            img {
                display: block;

                width: 100%;
            }
        }

        .right {
            width: 1080px;
            padding-top: 16px;

            @media screen and (max-width: 768px) {
                display: flex;
                flex-direction: column;

                width: auto;
                // align-items: flex-start;
                padding-top: 0;
            }

            .icon-row {
                display: flex;
                align-items: center;
                flex-direction: row;

                margin-bottom: 17px;

                .icon-wrapper {
                    width: 160px;
                    margin-right: 50px;
                    // margin-bottom: 40px;

                    @media screen and (max-width: 768px) {
                        align-self: flex-start;

                        width: 67/320 * 100vw;
                        // margin-bottom: 16/320 * 100vw;
                        margin-left: 26/320 * 100vw;
                    }

                    img {
                        display: block;

                        width: 100%;
                    }
                }

                .icon-logo-text-wrapper {
                    width: 460px;

                    @media screen and (max-width: 768px) {
                        width: 460/768 * 100vw;
                    }

                    img {
                        display: block;

                        width: 100%;
                    }
                }
            }

            .title {
                margin-bottom: 16px;

                @media screen and (max-width: 768px) {
                    align-self: flex-start;

                    margin-bottom: 15/320 * 100vw;
                    margin-left: 26/320 * 100vw;
                }
            }

            .desc {
                width: 100%;
                margin-bottom: 48px;

                white-space: pre-wrap;

                color: #000000;

                @media screen and (max-width: 768px) {
                    align-self: flex-start;

                    width: 253/320 * 100vw;
                    margin-bottom: 40/320 * 100vw;
                    margin-left: 26/320 * 100vw;
                }
            }

            .platfom-download {
                display: flex;
                flex-direction: row;

                margin-bottom: 40px;

                @media screen and (max-width: 768px) {
                    justify-content: center;

                    margin-bottom: 50/768 * 100vw;
                }

                .appstore-icon,
                .google-play-icon {
                    width: 290px;

                    cursor: pointer;

                    @media screen and (max-width: 768px) {
                        width: 120/320 * 100vw;
                    }

                    img {
                        display: block;

                        width: 100%;
                    }
                }

                .appstore-icon {
                    margin-right: 48px;

                    @media screen and (max-width: 768px) {
                        margin-right: 20/320 * 100vw;
                    }
                }
            }

            .google-play-best {
                display: flex;
                flex-direction: row;

                @media screen and (max-width: 768px) {
                    justify-content: center;

                    margin-bottom: 77/320 * 100vw;
                }

                .googleplay-best-icon {
                    width: 190px;
                    margin-right: 40px;

                    @media screen and (max-width: 768px) {
                        width: 82/320 * 100vw;
                        margin-right: 20/320 * 100vw;

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    img {
                        display: block;

                        width: 100%;
                    }
                }
            }
        }
    }
    .contentAspin {
        display: flex;
        flex-direction: row;
        // justify-content: center;

        padding-top: 168px;
        // padding-bottom: 128px;

        @media screen and (max-width: 768px) {
            align-items: center;
            flex-direction: column;

            padding-top: 40/320 * 100vw;
        }

        .left {
            height: 760px;
            margin-right: 60px;

            @media screen and (max-width: 768px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 6/320 * 100vw;
            }

            img {
                display: block;

                height: 100%;
            }
        }

        .right {
            width: 1080px;
            padding-top: 16px;
            padding-bottom: 128px;
            @media screen and (max-width: 768px) {
                display: flex;
                flex-direction: column;

                width: auto;
                // align-items: flex-start;
                padding-top: 0;
            }

            .icon-row {
                display: flex;
                align-items: center;
                flex-direction: row;

                margin-bottom: 17px;

                .icon-wrapper {
                    width: 160px;
                    margin-right: 50px;
                    // margin-bottom: 40px;

                    @media screen and (max-width: 768px) {
                        align-self: flex-start;

                        width: 67/320 * 100vw;
                        // margin-bottom: 16/320 * 100vw;
                        margin-left: 26/320 * 100vw;
                    }

                    img {
                        display: block;

                        width: 100%;
                    }
                }

                .icon-logo-text-wrapper {
                    width: 460px;

                    @media screen and (max-width: 768px) {
                        width: 460/768 * 100vw;
                    }

                    img {
                        display: block;

                        width: 100%;
                    }
                }
            }

            .title {
                margin-bottom: 16px;

                @media screen and (max-width: 768px) {
                    align-self: flex-start;

                    margin-bottom: 15/320 * 100vw;
                    margin-left: 26/320 * 100vw;
                }
            }

            .desc {
                width: 100%;
                margin-bottom: 48px;

                white-space: pre-wrap;

                color: #000000;

                @media screen and (max-width: 768px) {
                    align-self: flex-start;

                    width: 253/320 * 100vw;
                    margin-bottom: 40/320 * 100vw;
                    margin-left: 26/320 * 100vw;
                }
            }

            .platfom-download {
                display: flex;
                flex-direction: row;

                margin-bottom: 40px;

                @media screen and (max-width: 768px) {
                    justify-content: center;

                    margin-bottom: 50/768 * 100vw;
                }

                .appstore-icon,
                .google-play-icon {
                    width: 290px;

                    cursor: pointer;

                    @media screen and (max-width: 768px) {
                        width: 120/320 * 100vw;
                    }

                    img {
                        display: block;

                        width: 100%;
                    }
                }

                .appstore-icon {
                    margin-right: 48px;

                    @media screen and (max-width: 768px) {
                        margin-right: 20/320 * 100vw;
                    }
                }
            }

            .google-play-best {
                display: flex;
                flex-direction: row;

                @media screen and (max-width: 768px) {
                    justify-content: center;

                    margin-bottom: 77/320 * 100vw;
                }

                .googleplay-best-icon {
                    width: 190px;
                    margin-right: 40px;

                    @media screen and (max-width: 768px) {
                        width: 82/320 * 100vw;
                        margin-right: 20/320 * 100vw;

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    img {
                        display: block;

                        width: 100%;
                    }
                }
            }
        }
    }
}
