.contact-content-1-view {
    height: auto;

    background-color: rgba(246, 246, 251, 0.7);

    .content {
        display: flex;
        flex-direction: row;
        justify-content: center;

        @media screen and (max-width: 768px) {
            align-items: center;
            flex-direction: column;

            text-align: center;
        }

        .left {
            width: 551px;
            margin-top: 173px;
            margin-right: 77px;

            @media screen and (max-width: 768px) {
                width: 551/768 * 100vw;
                margin-top: 116/768 * 100vw;
            }

            img {
                display: block;

                width: 100%;
            }
        }
        .right {
            padding-top: 256px;

            .title {
                white-space: pre-wrap;

                color: #000000;
            }
            .desc {
                width: 1148px;

                white-space: pre-wrap;

                color: #000000;

                @media screen and (max-width: 768px) {
                    width: 253/320 * 100vw;
                }
            }
        }
    }
}
