.career-banner-view {
    @media screen and (max-width: 768px) {
        height: 417/320 * 100vw;
    }

    .content {
        position: absolute;
        top: 380px;
        left: 50%;

        transform: translateX(-50%);
        text-align: center;

        @media screen and (max-width: 768px) {
            top: 140/320 * 100vw;
        }

        .title {
            margin-bottom: 8px;

            white-space: pre-wrap;

            color: #ffffff;

            @media screen and (max-width: 768px) {
                margin-bottom: 10/320 * 100vw;
            }
        }

        .desc {
            white-space: pre-wrap;

            color: #ffffff;

            font-size: 30px;

            @media screen and (max-width: 768px) {
                font-size: 13/320 * 100vw;
            }
        }
    }
}
