.footer {
    position: relative;

    padding: 70px 0 90px;

    background-color: #17171d;

    @media screen and (max-width: 1281px) {
        padding: 32px 0 50px;
    }

    @media screen and (max-width: 768px) {
        padding-top: 40/320 * 100vw;
        padding-bottom: 30/320 * 100vw;
        padding-left: 20/320 * 100vw;
    }

    .share-link-list {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;

        margin-bottom: 48px;

        @media screen and (max-width: 1281px) {
            margin-bottom: 24px;
        }

        @media screen and (max-width: 768px) {
            justify-content: flex-start;

            margin-bottom: 37/320 * 100vw;
        }

        .share-link-item {
            margin-right: 56px;

            @media screen and (max-width: 1281px) {
                margin-right: 32px;
            }

            @media screen and (max-width: 768px) {
                margin-right: 20/320 * 100vw;
            }

            .anticon {
                cursor: pointer;

                color: #757575;

                font-size: 48px;

                @media screen and (max-width: 1281px) {
                    font-size: 28px;
                }

                @media screen and (max-width: 768px) {
                    font-size: 24/320 * 100vw;
                }

                &.anticon-youtube {
                    @media screen and (max-width: 768px) {
                        font-size: 9.5vw;
                    }
                }

                &:hover {
                    color: rgba(255, 255, 255, 0.6);
                }
            }
        }
    }

    .website-info-list {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 768px) {
            align-items: flex-start;
            flex-direction: column;
        }

        .logo-wrapper {
            display: inline-block;
            overflow: hidden;

            width: 44px;

            @media screen and (max-width: 1281px) {
                width: 22px;
            }

            @media screen and (max-width: 768px) {
                width: 27/320 * 100vw;
            }

            img {
                display: block;

                width: 100%;
            }
        }

        .website-info-item {
            margin-right: 68px;
            margin-bottom: 49px;

            @media screen and (max-width: 768px) {
                margin-bottom: 10/320 * 100vw;

                &:first-child {
                    position: absolute;
                    right: 24/320 * 100vw;
                    bottom: -1vw;
                }
            }

            &.toggle-local {
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }

            a,
            span {
                cursor: pointer;

                color: #757575;

                font-family: 'Rubik', sans-serif;
                font-size: 19px;
                font-weight: bold;
                line-height: 1.2;

                &:hover {
                    color: rgba(255, 255, 255, 0.6);
                }

                @media screen and (max-width: 1281px) {
                    font-size: 12px;
                }

                @media screen and (max-width: 768px) {
                    font-size: 13/320 * 100vw;
                }

                &.active {
                    color: #fff;
                }
            }
        }
    }

    .copyright {
        text-align: center;

        color: #757575;

        font-family: 'Rubik', sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.2;

        @media screen and (max-width: 1281px) {
            font-size: 12px;
        }

        @media screen and (max-width: 768px) {
            padding-top: 59/768 * 100vw;

            text-align: left;

            font-size: 13/320 * 100vw;
        }
        a{
        color: #757575;}
    }
}
