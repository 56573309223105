.game-detail-content-2-view {
    .content {
        position: absolute;
        top: 30%;
        left: 54.65%;

        @media screen and (max-width: 768px) {
            top: 55/320 * 100vw;
            left: 50%;

            width: 285/320 * 100vw;

            transform: translateX(-50%);
        }

        .title {
            text-align: center;

            color: #ffffff;

            @media screen and (max-width: 768px) {
                margin-bottom: 27/320 * 100vw;

                font-weight: 500;
            }
        }

        .desc-list {
            width: 864px;

            list-style: disc;
            list-style-position: inside;

            text-align: center;

            color: #ffffff;

            font-family: 'Rubik', sans-serif;
            font-size: 21px;
            font-weight: 400;
            line-height: 1.8;

            @media screen and (max-width: 1281px) {
                font-size: 12px;
            }

            @media screen and (max-width: 768px) {
                width: 100%;

                font-size: 13/320 * 100vw;
                line-height: 1.4;
            }
        }
    }
}
