/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 300;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXO61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-weight: 300;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXq61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-weight: 300;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXy61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 300;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Rubik';
    font-weight: 300;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 400;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXO61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-weight: 400;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXq61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-weight: 400;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXy61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 400;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Rubik';
    font-weight: 400;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 500;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXO61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-weight: 500;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXq61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-weight: 500;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXy61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 500;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Rubik';
    font-weight: 500;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 600;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXO61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-weight: 600;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXq61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-weight: 600;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXy61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 600;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Rubik';
    font-weight: 600;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 700;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXO61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-weight: 700;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXq61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-weight: 700;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXy61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 700;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Rubik';
    font-weight: 700;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 800;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXO61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-weight: 800;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXq61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-weight: 800;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXy61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 800;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Rubik';
    font-weight: 800;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 900;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXO61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-weight: 900;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXq61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-weight: 900;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXy61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 900;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Rubik';
    font-weight: 900;
    font-style: italic;

    font-display: swap;
    src: url(./rubik/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 300;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-weight: 300;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-weight: 300;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 300;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Rubik';
    font-weight: 300;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nBrXyw023e.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 400;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-weight: 400;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-weight: 400;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 400;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Rubik';
    font-weight: 400;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nBrXyw023e.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 500;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-weight: 500;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-weight: 500;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 500;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Rubik';
    font-weight: 500;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nBrXyw023e.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 600;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-weight: 600;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-weight: 600;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 600;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Rubik';
    font-weight: 600;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nBrXyw023e.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 700;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-weight: 700;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-weight: 700;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 700;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Rubik';
    font-weight: 700;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nBrXyw023e.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 800;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-weight: 800;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-weight: 800;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 800;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Rubik';
    font-weight: 800;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nBrXyw023e.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 900;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-weight: 900;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-weight: 900;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-weight: 900;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Rubik';
    font-weight: 900;
    font-style: normal;

    font-display: swap;
    src: url(./rubik/iJWKBXyIfDnIV7nBrXyw023e.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
