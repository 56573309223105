.foot {
    width: 100%;
    height: auto;
    position: inherit;
    transform: translateX(0);
    .down-container {
        width: 60vw;
        max-width: 1500px;
        height: auto;
        margin: 0 auto;
        background: #fff;
        position: relative;

        @media screen and (max-width: 768px) {
            width: 100vw;
            max-width: 100vw;
            margin-top: 16vw;
        }
        img {
            width: 100%;
            height: auto;
            display: block;
        }
        .btn {
            color: #fff;
            border-radius: 0.278vw;
            cursor: pointer;
            a {
                color: #fff;
                .btnWrap {
                    position: absolute;
                    top: 50%;
                    left: 5.4vw;
                    width: 400px;
                    height: auto;
                    @media screen and (max-width: 768px) {
                        width: 100px;
                        top: 18%;
                    }
                }
            }
            @media screen and (max-width: 768px) {
                font-size: 0.678rem;
                padding: 2.8vw 2.6vw;
                left: 6%;
            }
        }
        .aClick {
            position: absolute;
            bottom: 10%;
            left: 5.4vw;
            @media screen and (max-width: 768px) {
                left: 5vw;
                font-size: 0.6rem;
            }
            a {
                text-decoration: underline;
            }
        }
        .desc {
            position: absolute;
            top: 0;
            left: 5.4vw;
            font-weight: 300;
            word-spacing: 0.4vw;
            font-size: 1.2rem;
            @media screen and (max-width: 768px) {
                font-size: 1rem;
                left: 5vw;
                top: -16vw;
            }
        }
    }
}
