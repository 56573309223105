.career-content-2-view {
    height: auto;

    .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin: 0 480px;
        padding-top: 96px;
        padding-bottom: 140px;

        @media screen and (max-width: 768px) {
            align-items: center;
            flex-direction: column-reverse;

            margin: 0;
            padding-top: 43/320 * 100vw;
            padding-bottom: 35/320 * 100vw;
        }

        .career-card {
            width: 720px;
            margin-top: 72px;

            @media screen and (max-width: 768px) {
                width: 295/320 * 100vw;
                margin-top: 38/320 * 100vw;
            }

            .inner {
                display: flex;
                align-items: center;
                flex-direction: column;

                padding: 0 64px;

                text-align: center;

                background-color: #ffffff;

                @media screen and (max-width: 768px) {
                    padding: 0 12/320 * 100vw;
                }
            }

            .image-wrapper {
                overflow: hidden;

                width: 184px;
                height: 184px;
                margin-top: -72px;
                margin-bottom: 6px;

                @media screen and (max-width: 768px) {
                    overflow: hidden;

                    width: 76/320 * 100vw;
                    height: 76/320 * 100vw;
                    margin-top: -38/320 * 100vw;
                    margin-bottom: 3/320 * 100vw;
                }

                img {
                    display: block;

                    width: 100%;
                }
            }

            .title {
                margin-bottom: 6px;

                white-space: pre-wrap;

                color: #000000;

                @media screen and (max-width: 768px) {
                    margin-bottom: 3/320 * 100vw;
                }
            }

            .sub-title {
                margin-bottom: 30px;

                white-space: pre-wrap;

                color: #f84724;

                @media screen and (max-width: 768px) {
                    margin-bottom: 13/320 * 100vw;
                }
            }

            .desc {
                white-space: pre-wrap;

                color: #171717;

                font-family: 'Rubik', sans-serif;
                font-size: 25px;
                font-weight: 500;
                line-height: 1.4;

                @media screen and (max-width: 768px) {
                    font-size: 11/320 * 100vw;
                }
            }
        }
    }
}
