.about-content-2-view {
    .content {
        margin-bottom: 240px;

        .time-line-list {
            display: flex;
            align-items: center;
            flex-direction: column;

            .line-ellipsis {
                width: 6px;
                margin: 16px 0;

                @media screen and (max-width: 768px) {
                    display: none;
                }

                img {
                    display: block;

                    width: 100%;
                }
            }

            .time-line-item {
                position: relative;

                display: flex;
                flex-direction: row;

                @media screen and (max-width: 768px) {
                    flex-direction: column;

                    margin-bottom: 178/320 * 100vw;

                    &:nth-of-type(odd) {
                        display: none;
                    }
                }

                &.layout-left {
                    flex-direction: row-reverse;

                    @media screen and (max-width: 768px) {
                        flex-direction: column;
                    }

                    .left {
                        flex-direction: row-reverse;

                        @media screen and (max-width: 768px) {
                            flex-direction: column;
                        }

                        .time-text {
                            text-align: left;
                        }
                    }
                }

                .left {
                    display: flex;
                    flex-direction: row;

                    width: 700px;
                    padding-top: 24px;

                    @media screen and (max-width: 768px) {
                        flex-direction: column;

                        width: auto;
                        padding-top: 0;
                    }

                    .image-wrapper {
                        width: 464px;

                        @media screen and (max-width: 768px) {
                            position: absolute;
                            bottom: 0;

                            width: 195/320 * 100vw;

                            transform: translateY(100%);
                        }

                        img {
                            display: block;

                            width: 100%;
                        }
                    }

                    .time-text {
                        flex: 1;

                        padding-top: 48px;
                        padding-right: 24px;
                        padding-left: 24px;

                        text-align: right;

                        color: #ababab;

                        font-family: 'Rubik', sans-serif;
                        font-size: 19px;
                        font-weight: 500;
                        line-height: 1.2;

                        @media screen and (max-width: 768px) {
                            padding-left: 0;

                            text-align: left;

                            font-size: 8/320 * 100vw;
                        }
                    }
                }

                .line-block {
                    position: relative;

                    display: flex;
                    flex: 1;

                    width: 56px;
                    padding-top: 56px;
                    padding-bottom: 63px;

                    @media screen and (max-width: 768px) {
                        display: none;
                    }

                    .line-pointer {
                        width: 56px;

                        img {
                            display: block;

                            width: 100%;
                        }
                    }

                    .line {
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 50%;

                        width: 6px;
                        height: 100%;

                        transform: translateX(-50%);

                        background-color: #e8e8e8;
                    }
                }

                .content-main {
                    width: 700px;
                    padding-top: 62px;
                    padding-left: 24px;

                    @media screen and (max-width: 768px) {
                        width: 251/320 * 100vw;
                        padding-left: 0;
                        // padding-right: 41/320 * 100vw;
                    }

                    .title {
                        margin-bottom: 12px;

                        color: #e60013;

                        font-family: 'Rubik', sans-serif;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 1.8;

                        @media screen and (max-width: 768px) {
                            margin-bottom: 5/320 * 100vw;

                            font-size: 10/320 * 100vw;
                        }
                    }
                    .desc {
                        color: #000000;

                        font-weight: bold;

                        @media screen and (max-width: 768px) {
                            margin-bottom: 10/320 * 100vw;

                            font-size: 9/320 * 100vw;
                        }
                    }
                }
            }
        }
    }
}
