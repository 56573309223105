.game-content-2-view {
    .content {
        position: absolute;
        top: 29.37%;
        left: 54.65%;

        @media screen and (max-width: 768px) {
            top: 55/320 * 100vw;
            left: 50%;

            display: flex;
            align-items: center;
            flex-direction: column;

            width: 100%;

            transform: translateX(-50%);
            text-align: center;
        }

        .title {
            white-space: pre-wrap;

            color: #ffffff;

            @media screen and (max-width: 768px) {
                width: 204/320 * 100vw;
            }
        }

        .desc-list {
            margin-bottom: 72px;

            list-style: disc;
            list-style-position: inside;

            white-space: pre-wrap;

            color: #ffffff;

            font-family: 'Rubik', sans-serif;
            font-size: 21px;
            font-weight: 400;
            line-height: 1.8;

            @media screen and (max-width: 1281px) {
                margin-bottom: 40px;

                font-size: 12px;
            }

            @media screen and (max-width: 768px) {
                width: 266/320 * 100vw;
                margin-bottom: 30/320 * 100vw;

                font-size: 13/320 * 100vw;
            }
        }
    }
}
