.game-detail-souls{
    height: auto;
    a {
        cursor: pointer;
    }
    img{
        width: 100%;
        height: auto;
        display: block;
    }
    .view1{
        position: relative;
        min-height: 1200/2560 * 100vw;
        @media screen and (max-width: 768px) {
            min-height: 1200/768 * 100vw;
        }
        .downClass{
            position: absolute;
            bottom: 74/2560 * 100vw;
            left: 50%;
            transform: translateX(-50%);
            height: 62/2560 * 100vw;
            width: 620/2560 * 100vw;
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 768px) {
                bottom: 260/2560 * 100vw;
                height: 240/2560 * 100vw;
                width: 2200/2560 * 100vw;
            }
            a{
                display: block;
                width: 31%;
            }
        }
    }
    .view5{
        position: relative;
        min-height: 1000/2560 * 100vw;
        .Wrap{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 2200/2560 * 100vw;
            bottom: 0;
            height: 1030/2560 * 100vw;
            @media screen and (min-width: 768px) {
                height: 390/2560 * 100vw;
                width: 640/2560 * 100vw;
                left: 72%;
            }
            .downClass{
                height: 240/2560 * 100vw;
                width: 2200/2560 * 100vw;
                display: flex;
                justify-content: space-between;
                @media screen and (min-width: 768px) {
                    height: 60/2560 * 100vw;
                    width: 640/2560 * 100vw;
                }
                a {
                    display: block;
                    width: 32%;
                }
            }
            .mediaClass{
                height: 180/2560 * 100vw;
                display: flex;
                justify-content: space-between;
                margin-top: 400/2560 * 100vw;
                padding: 0 31.4%;
                @media screen and (min-width: 768px) {
                    height: 50/2560 * 100vw;
                    margin-top: 110/2560 * 100vw;
                }
                a {
                    display: block;
                    width: 21%;
                }
            } 
        }
    }
}
