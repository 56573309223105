.header {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 50%;

    display: flex;
    flex-direction: row;
    // width: 78.125%;

    width: 2000px;

    transform: translateX(-50%);

    border-radius: 0px 0px 10px 10px;
    background-color: #151520;

    @media screen and (max-width: 1024px) {
        width: 100%;
    }

    .logo-wrapper {
        position: relative;

        overflow: hidden;

        width: 192px;
        // prettier-ignore
        height: 108px;

        border-radius: 0px 0px 10px 10px;
        background: linear-gradient(162deg, #ff6a4c 0%, #dc2826 100%);

        @media screen and (max-width: 1681px) {
            height: 70.88px;
        }

        @media screen and (max-width: 1281px) {
            height: 60px;
        }

        @media screen and (max-width: 768px) {
            width: 80/320 * 100vw;
            height: 45/320 * 100vw;
        }

        .logo {
            position: absolute;
            top: 50%;
            left: 50%;

            width: 50px;

            transform: translateX(-50%) translateY(-50%);

            @media screen and (max-width: 768px) {
                width: 21/320 * 100vw;
            }

            img {
                display: block;

                width: 100%;
            }
        }
    }

    .header-content {
        display: flex;
        flex: 1;
        justify-content: flex-end;

        .nav-trigger {
            display: none;
            align-self: center;

            height: 100%;
            padding: 0 50px;

            color: #ffffff;

            @media screen and (max-width: 1024px) {
                display: inline-flex;
                align-items: center;

                padding: 0 25/320 * 100vw;

                &[data-collapse='true'] {
                    ~ .nav-list {
                        display: none;
                    }
                }

                &[data-collapse='false'] {
                    ~ .nav-list {
                        display: flex;
                    }
                }
            }

            @media screen and (max-width: 768px) {
                font-size: 14/320 * 100vw;
            }
        }

        .nav-list {
            display: flex;
            align-items: center;
            flex: 1;
            flex-direction: row;
            justify-content: flex-end;

            @media screen and (max-width: 1024px) {
                position: absolute;
                right: 0;
                bottom: 0;

                display: none;
                align-items: flex-end;
                flex-direction: column;

                width: 183/320 * 100vw;

                transform: translateY(100%-1px);

                background-color: rgba(21, 21, 32, 0.9);
            }

            @media screen and (max-width: 768px) {
                padding-top: 7/768 * 100vw;
            }

            .toggle-local {
                display: none;

                @media screen and (max-width: 1024px) {
                    display: block;
                }
            }

            .nav-item {
                margin-right: 68px;

                @media screen and (max-width: 1024px) {
                    margin-right: 25/320 * 100vw;
                    padding: 0.8em 0;
                }

                @media screen and (max-width: 768px) {
                    margin-right: 60/768 * 100vw;
                    padding: 27/768 * 100vw 0;

                    letter-spacing: 4/768 * 100vw;

                    font-size: 36/768 * 100vw;
                    font-weight: 500;
                    line-height: 43/768 * 100vw;
                }

                &:last-child {
                    margin-right: 54px;

                    @media screen and (max-width: 1024px) {
                        margin-right: 25/320 * 100vw;
                        padding-bottom: 32/320 * 100vw;
                    }
                }

                a {
                    letter-spacing: 1px;

                    color: rgba(255, 255, 255, 0.7);

                    font-family: 'Rubik', sans-serif;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 1.2;

                    @media screen and (max-width: 1024px) {
                        font-size: 15/320 * 100vw;
                    }

                    // @media screen and (max-width: 1281px) {
                    //     font-size: 12px;
                    // }

                    &.active {
                        font-family: 'Rubik', sans-serif;
                        font-weight: 500;
                    }

                    &.active,
                    &:hover {
                        color: rgba(255, 255, 255, 1);
                    }
                }
            }
        }
    }
}
