.game-detail-content-3-view {
    background-color: #f6f6fb;
    height: auto;

    .content {
        .display-images {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-top: 150px;
            padding-bottom: 225px;

            @media screen and (max-width: 768px) {
                flex-direction: row;
                flex-wrap: wrap;
                padding-top: 57/320 * 100vw;
                padding-bottom: 47/320 * 100vw;
            }

            .display-image {
                width: 404px;
                margin: 0 18px;

                @media screen and (max-width: 768px) {
                    width: 135/320 * 100vw;
                    margin: 0 6/320 * 100vw 2.875vw;
                }

                img {
                    display: block;
                    width: 100%;
                }
            }
        }

        .game-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #ffffff;

            @media screen and (max-width: 768px) {
                margin-top: 40/320 * 100vw;
            }

            .logo-wrapper {
                width: 200px;
                margin-bottom: 24px;
                margin-top: -126px;

                @media screen and (max-width: 768px) {
                    width: 83/320 * 100vw;
                    margin-top: -40/320 * 100vw;
                    margin-bottom: 8/320 * 100vw;
                }

                img {
                    display: block;
                    width: 100%;
                }
            }

            .game-name {
                color: #000000;
                margin-bottom: 80px;

                @media screen and (max-width: 768px) {
                    margin-bottom: 28/320 * 100vw;
                }
            }

            .platfom-download {
                display: flex;
                flex-direction: row;
                margin-bottom: 136px;
                justify-content: center;

                @media screen and (max-width: 768px) {
                    margin-bottom: 63/320 * 100vw;
                }

                .appstore-icon,
                .google-play-icon {
                    width: 290px;
                    cursor: pointer;

                    @media screen and (max-width: 768px) {
                        width: 120/320 * 100vw;
                    }

                    img {
                        display: block;
                        width: 100%;
                    }
                }

                .appstore-icon {
                    margin-right: 48px;

                    @media screen and (max-width: 768px) {
                        margin-right: 20/320 * 100vw;
                    }
                }
            }
        }
    }
}
