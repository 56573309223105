.career-content-3-view {
    .content {
        position: absolute;
        top: 150px;

        display: flex;
        align-items: center;
        flex-direction: column;

        width: 100%;

        text-align: center;

        @media screen and (max-width: 768px) {
            top: 82/320 * 100vw;
        }

        .title {
            white-space: pre-wrap;

            color: #ffffff;
        }

        .desc {
            width: 1018px;

            white-space: pre-wrap;

            color: #ffffff;

            @media screen and (max-width: 768px) {
                width: 266/320 * 100vw;
            }
        }

        .location-list {
            display: flex;
            flex-direction: row;
            justify-content: center;

            width: 100%;

            .location-item {
                display: flex;
                align-items: center;
                flex-direction: column;

                width: 240px;
                margin: 0 160px;

                text-align: center;

                @media screen and (max-width: 768px) {
                    width: 99/320 * 100vw;
                    margin: 0 22/320 * 100vw 8/320 * 100vw;
                }

                img {
                    width: 100%;
                    margin-bottom: 20px;
                }

                .location-title {
                    margin-bottom: 18px;

                    white-space: pre-wrap;

                    color: #ffffff;

                    font-family: 'Rubik', sans-serif;
                    font-size: 30px;
                    font-weight: bold;
                    line-height: 1.2;

                    @media screen and (max-width: 768px) {
                        margin-bottom: 8/320 * 100vw;

                        font-size: 12/320 * 100vw;
                    }
                }

                .location-desc {
                    white-space: pre-wrap;

                    color: #f83c34;
                    // font-family: 'ProductSans-Bold', ProductSans;

                    font-family: 'Rubik', sans-serif;
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 1.2;

                    @media screen and (max-width: 768px) {
                        font-size: 10/320 * 100vw;
                    }
                }
            }
        }
    }
}
