.career-content-4-view {
    position: relative;

    height: auto;

    // @media screen and (max-width: 768px) {
    //     height: 705/320 * 100vw;
    // }

    .anticon-caret-right {
        display: none;

        @media screen and (max-width: 768px) {
            position: absolute;
            top: 50%;
            right: 18/320 * 100vw;

            display: block;

            transform: translateY(-50%);

            color: rgba(202, 202, 202, 1);
        }
    }

    .content {
        width: 44.69%;
        margin: 0 auto;
        padding-top: 136px;
        padding-bottom: 207px;

        @media screen and (max-width: 1024px) {
            width: 60%;
        }

        @media screen and (max-width: 768px) {
            width: 91.02%;
            padding-top: 140/768 * 100vw;
        }

        .title {
            margin-bottom: 22px;

            text-align: center;
            white-space: pre-wrap;

            color: #ffffff;

            @media screen and (max-width: 768px) {
                margin-bottom: 40/768 * 100vw;

                font-size: 50/768 * 100vw;
                line-height: 60/768 * 100vw;
            }
        }

        .sub-desc {
            margin-bottom: 47px;

            text-align: center;
            white-space: pre-wrap;

            color: #ffffff;

            font-family: 'Rubik', sans-serif;
            font-size: 21px;
            font-weight: 400;
            line-height: 1.7;

            @media screen and (max-width: 768px) {
                font-size: 30/768 * 100vw;
                line-height: 45/768 * 100vw;
            }

            span {
                height: 36px;
                padding-left: 10px;

                color: #ffffff;

                font-family: 'Rubik', sans-serif;
                font-size: 21px;
                font-weight: bold;
                line-height: 36px;

                @media screen and (max-width: 768px) {
                    font-size: 30/768 * 100vw;
                    line-height: 45/768 * 100vw;
                }
            }

            @media screen and (max-width: 768px) {
                margin-bottom: 40/768 * 100vw;
            }
        }

        .hiring-list {
            display: flex;
            flex-direction: column;
            // padding: 0 28%;

            // @media screen and (max-width: 768px) {
            //     padding: 0 15/320 * 100vw;
            // }

            .hiring-item {
                position: relative;

                display: inline-flex;
                align-items: center;
                flex-direction: row;

                margin-bottom: 20px;

                text-align: left;

                border-radius: 10px;
                background: #ffffff;
                box-shadow: 0px 10px 14px 0px rgba(0, 0, 0, 0.02);

                @media screen and (max-width: 768px) {
                    align-items: flex-start;
                    flex-direction: column;

                    margin-bottom: 10/320 * 100vw;
                    padding: 12/320 * 100vw 20/320 * 100vw;

                    border-radius: 4/320 * 100vw;
                }

                .hiring-item-title {
                    flex: 1;

                    padding-left: 40px;

                    color: #000000;

                    font-family: 'Rubik', sans-serif;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 1.2;

                    @media screen and (max-width: 1281px) {
                        font-size: 12px;
                    }

                    @media screen and (max-width: 768px) {
                        margin-bottom: 4/320 * 100vw;
                        padding-left: 0;

                        font-size: 10/320 * 100vw;
                    }
                }

                .hiring-item-location {
                    min-width: 280px;

                    color: #e94034;

                    font-family: 'Rubik', sans-serif;
                    font-size: 21px;
                    font-weight: 500;
                    line-height: 1.2;

                    @media screen and (max-width: 1281px) {
                        font-size: 12px;
                    }

                    @media screen and (max-width: 768px) {
                        font-size: 9/320 * 100vw;
                    }
                }

                .hiring-item-time {
                    min-width: 200px;

                    color: rgba(0, 0, 0, 0.44);

                    font-family: 'Rubik', sans-serif;
                    font-size: 21px;
                    font-weight: 500;
                    line-height: 1.2;

                    @media screen and (max-width: 1281px) {
                        font-size: 12px;
                    }

                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                }

                .hiring-button {
                    width: 136px;
                    height: 70px;

                    cursor: pointer;

                    color: #ffffff;
                    border: 0;
                    border-radius: 0px 10px 10px 0px;
                    background: linear-gradient(
                        120deg,
                        #ff6a4c 0%,
                        #dc2826 100%
                    );

                    font-family: 'Rubik', sans-serif;
                    font-size: 19px;
                    font-weight: bold;
                    line-height: 1.2;

                    &:hover {
                        opacity: 0.9;
                    }

                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
    }
}

.career-detail-modal {
    overflow: hidden;

    width: 1024px !important;
    padding-bottom: 0;

    border-radius: 20px;

    .ant-modal-body {
        padding-top: 115px;
        padding-right: 105px;
        padding-bottom: 150px;
        padding-left: 105px;
    }

    .title {
        position: relative;

        margin-bottom: 28px;
        padding-bottom: 24px;

        color: #000000;

        font-family: 'Rubik', sans-serif;
        font-size: 50px;
        font-weight: 500;
        line-height: 60px;

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 138px;
            height: 6px;

            content: ' ';

            background-color: #ee0000;
        }
    }

    .location {
        margin-bottom: 110px;

        color: rgba(0, 0, 0, 0.4);

        font-family: 'Rubik', sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
    }

    .desc-title {
        margin-bottom: 34px;

        color: #000000;

        font-family: 'Rubik', sans-serif;
        font-size: 18px;
        font-weight: bold;
        line-height: 26px;
    }

    .desc-content {
        margin-bottom: 34px;

        white-space: pre-wrap;

        color: #000000;

        font-family: 'Rubik', sans-serif;
        font-size: 18px;
        line-height: 26px;
    }

    .ant-modal-close {
        .ant-modal-close-icon {
            font-size: 14px !important;
        }
    }

    a.button {
        font-family: 'PingFangSC-Semibold', 'PingFang SC';
        font-size: 19px;
        font-weight: 600;
        line-height: 26px;
    }

    a.button:hover {
        opacity: 0.7;
    }
}
