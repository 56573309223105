.career-content-1-view {
    height: auto;

    .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        padding: 64px 500px 0;

        @media screen and (max-width: 768px) {
            align-items: center;
            flex-direction: column;

            padding: 48/320 * 100vw 34/320 * 100vw 0;

            text-align: center;
        }

        .left {
            display: flex;
            flex-direction: column;
            justify-content: center;

            margin-right: 360px;

            @media screen and (max-width: 768px) {
                margin-right: 0;
                margin-bottom: 45/320 * 100vw;
            }

            .title {
                white-space: pre-wrap;

                color: #000000;
            }

            .desc {
                white-space: pre-wrap;

                color: #000000;
            }
        }

        .right {
            .image-wrapper {
                width: 440px;

                @media screen and (max-width: 768px) {
                    width: 183/320 * 100vw;
                    margin-bottom: 27/320 * 100vw;
                }

                img {
                    display: block;

                    width: 100%;
                }
            }
        }
    }
}
