.about-content-1-view {
    height: auto;

    .content {
        padding: 0 480px;
        padding-top: 220px;

        @media screen and (max-width: 768px) {
            padding: 0 33/320 * 100vw 0;
        }

        .content-list {
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 768px) {
                margin-bottom: 72/320 * 100vw;
            }

            .content-list-item {
                display: flex;
                align-items: center;
                flex-direction: row;

                margin-bottom: 160px;

                @media screen and (max-width: 768px) {
                    align-items: flex-start;
                    align-items: center;
                    flex-direction: column;

                    margin-bottom: 0;
                    padding-top: 60/320 * 100vw;

                    text-align: center;

                    justify-self: center;
                }

                img {
                    display: block;

                    width: 260px;
                    margin-right: 133px;

                    @media screen and (max-width: 768px) {
                        width: 95/320 * 100vw;
                        margin-right: 0;
                        margin-bottom: 26/320 * 100vw;
                    }
                }

                .content-main {
                    .title {
                        color: #000000;

                        @media screen and (max-width: 768px) {
                            font-size: 21/320 * 100vw;
                        }
                    }
                    .desc {
                        margin-bottom: 0;

                        color: #000000;
                    }
                }
            }
        }
    }
}
