
.down-view {
    width: 100%;
    min-height: 100vh;
    background: #e6e6e6;
    color: #6e3d97;
    @media screen and (max-width: 768px) {
        background: #fff;
    }
    .white {
        width: 60vw;
        max-width: 1500px;
        height: 3vw;
        margin: 0 auto;
        background: #fff;
    }
}
