.contact-banner-view {
    @media screen and (max-width: 768px) {
        height: 417/320 * 100vw;
    }

    .content {
        position: absolute;
        top: 40%;
        left: 50%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        transform: translateX(-50%);
        text-align: center;

        @media screen and (max-width: 768px) {
            top: 141/320 * 100vw;
        }

        .title {
            white-space: pre-wrap;

            color: #ffffff;
        }

        .desc {
            width: 1000px;

            white-space: pre-wrap;

            color: rgba(255, 255, 255, 0.8);

            font-size: 30px;
            font-weight: 500;

            @media screen and (max-width: 768px) {
                width: 253/320 * 100vw;

                font-size: 13/320 * 100vw;
            }
        }
    }
}
