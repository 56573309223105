.home-content-1-view {
    .content {
        position: absolute;
        left: 50%;

        width: 1220px;
        margin-top: 300px;

        transform: translateX(-50%);
        text-align: center;

        @media screen and (max-width: 768px) {
            width: 606/768 * 100vw;
            margin-top: 56/320 * 100vw;
        }

        .title {
            white-space: pre-wrap;

            color: black;

            @media screen and (max-width: 768px) {
                margin-bottom: 18/320 * 100vw;
            }
        }

        .desc {
            white-space: pre-wrap;

            color: black;

            @media screen and (max-width: 768px) {
                margin-bottom: 28/320 * 100vw;

                line-height: 1.461538462;
            }
        }
    }
}
