.game-card-component {
    width: 580px;
    margin-bottom: 44px;
    text-align: center;
    background-color: #ffffff;
    @media screen and (max-width: 768px) {
        width: 265/320 * 100vw;
    }

    .image-wrapper {
        // overflow: hidden;
        margin-bottom: 30px;
        min-width: 580px;
        height: 430px;
        position: relative;
        // margin-bottom: 265/320 * 100% * 120/540 * 1/2px;
        @media screen and (max-width: 768px) {
            margin-bottom: 18/320 * 100vw;
            height: auto;
        }

        img {
            display: block;
            width: 100%;
        }
        .icon {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 9;
            width: 265/320 * 100% * 120/540;
            left: 0;
            right: 0;
            margin: auto;
            margin-bottom: -265/320 * 100% * 120/540 * 1/2px;
            img {
                display: block;
                width: 100%;
            }
        }
    }

    .title {
        color: #000000;
        // margin-bottom: 20px;
        margin-top:  10/320 * 100vw;
        @media screen and (max-width: 768px) {
            // margin-bottom: 7/320 * 100vw;
            margin-top: 32/320 * 100vw;
        }
    }

    .desc {
        color: #a2b2c1;
        min-height: 68px;
        margin: 32px 0;
        padding: 0 76px;
        line-height: 1.33;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 768px) {
            margin: 19/320 * 100vw 0;
            padding: 0 25/320 * 100vw;
        }
    }

    .game-card-button {
        margin-bottom: 48px;

        @media screen and (max-width: 768px) {
            margin-bottom: 32/320 * 100vw;
        }
    }
}
