.headerDown {
    width: 100%;
    height: auto;
    background: url('../img/bg.png') 100% 100% no-repeat;
    position: inherit;
    transform: translateX(0);
    .down-container {
        width: 60vw;
        max-width: 1500px;
        height: auto;
        margin: 0 auto;
        @media screen and (max-width: 768px) {
            width: 100vw;
            max-width: 100vw;
        }
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}
