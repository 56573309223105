.about-banner-view {
    @media screen and (max-width: 768px) {
        height: 417/320 * 100vw;
    }

    .logo-wrapper {
        position: absolute;
        top: 325px;
        left: 50%;

        width: 875px;

        transform: translateX(-50%);

        @media screen and (max-width: 768px) {
            top: 125/320 * 100vw;

            width: 435/768 * 100vw;
        }

        img {
            display: block;

            width: 100%;
        }
    }
}
