.game-content-1-view {
    display: flex;
    flex-flow: wrap;
    padding: 120px 360px 176px;
    justify-content: space-between;
    background: #f6f6fb;
    .desc{
        color: #AAAABE;
        font-weight: 500;
    }
    .game-card-component:nth-child(12){
        opacity: 0;
    }
    @media screen and (max-width: 768px) {
        padding: 55/320 * 100vw 28/320 * 100vw 22/320 * 100vw;
        .game-card-component{
            margin-top: 20px;
        }
        .game-card-component:nth-child(11){
            display: none;
        }
        .game-card-component:nth-child(12){
            display: none;
        }
    }
}
.game-card-component:nth-child(11),.game-card-component:nth-child(12)  {
    .image-wrapper,
    p,
    .game-card-button {
        display: none;
    }
    .shadow-card-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            margin: 0;
            color: #DBDEEA;
        }
    }
}
// .game-card-component:last-child {
//     opacity: 0;
//     height: 0;
//     .game-card-button {
//         opacity: 0;
//         display: none;
//     }
// }
