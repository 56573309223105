.game-banner-view {
    position: relative;

    @media screen and (max-width: 768px) {
        height: 417/320 * 100vw;
    }

    .content {
        position: absolute;
        bottom: 0;
        left: 50%;

        transform: translateX(-50%);
        text-align: center;

        @media screen and (max-width: 768px) {
            bottom: 0;
        }

        .title {
            white-space: pre-wrap;

            color: #ffffff;
        }

        .desc {
            width: 1050px;

            white-space: pre-wrap;

            color: rgba(255, 255, 255, 0.8);

            @media screen and (max-width: 768px) {
                width: 253/320 * 100vw;
            }
        }
    }
}
