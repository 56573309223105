.home-content-2-view {
    // height: 1212/768 * 100vw;
    .contentx {
        padding-top: 140px;
        padding-left: 330px;

        @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            flex-direction: column;

            padding: 0;
            padding-top: 100/768 * 100vw;
        }

        .title {
            display: flex;
            align-items: flex-end;

            margin: 10/768 * 100vw 0 8/768 * 100vw;

            white-space: pre-wrap;

            color: #ffffff;

            line-height: 1 !important;

            @media screen and (max-width: 768px) {
                align-items: center;
                flex-direction: column;

                margin-bottom: 40/768 * 100vw;

                font-size: 50/768 * 100vw;
                line-height: 60/768 * 100vw;
            }

            .logo-wrapper {
                display: inline-block;
                overflow: hidden;

                width: 520px;

                @media screen and (max-width: 768px) {
                    width: 460/768 * 100vw;
                    margin-right: 0;
                }

                img {
                    display: block;

                    width: 100%;
                }
            }
        }

        .google-play-wrapper {
            width: 694px;
            margin-bottom: 36px;

            @media screen and (max-width: 768px) {
                width: 676/768 * 100vw;
                margin-bottom: 20/768 * 100vw;
            }

            img {
                display: block;

                width: 100%;
            }
        }

        .desc {
            width: 808px;
            margin-bottom: 36px;

            white-space: pre-wrap;

            color: #ffffff;

            @media screen and (max-width: 768px) {
                width: 606/768 * 100vw;
                margin-bottom: 60/768 * 100vw;

                text-align: center;

                font-size: 30/768 * 100vw;
                line-height: 45/768 * 100vw;
            }
        }

        .wrap{
            @media screen and (max-width: 768px) {
                text-align: center;
            }
        }
        .platfom-download {
            margin-bottom: 10/768 * 100vw;

            display: flex;
            flex-direction: row;

            margin-right: 48px;

            @media screen and (max-width: 768px) {
                justify-content: center;

                margin-bottom: 20/768 * 100vw;
            }

            .appstore-icon,
            .google-play-icon {
                width: 290px;

                cursor: pointer;

                @media screen and (max-width: 768px) {
                    width: 80/320 * 100vw;
                }

                img {
                    display: block;

                    width: 100%;
                }
            }

            .appstore-icon {
                margin-right: 48px;

                @media screen and (max-width: 768px) {
                    margin-right: 20/320 * 100vw;
                }
            }
        }
    }
}
.sss{
    padding-left: 2.1vw;
    padding-right: 2.1vw;
    @media screen and (max-width: 768px) {
        padding-left: 9.4vw;
        padding-right: 9.4vw;
    }
}