.home-banner-view {
    @media screen and (max-width: 768px) {
        height: 417/320 * 100vw;
    }

    .content {
        position: absolute;
        left: 50%;

        width: 930px;
        margin-top: 268px;

        transform: translateX(-50%);

        @media screen and (max-width: 768px) {
            width: 265/320 * 100vw;
            margin-top: 110/320 * 100vw;
        }

        .logo-wrapper {
            width: 320px;
            margin: 0 auto 36px;

            @media screen and (max-width: 1281px) {
                width: 170px;
            }

            @media screen and (max-width: 768px) {
                width: 142/320 * 100vw;
                margin: 0 auto 16/320 * 100vw;
            }

            img {
                display: block;

                width: 100%;
            }
        }

        .desc {
            text-align: center;
            white-space: pre-wrap;

            color: #000000;

            font-size: 24px;

            @media screen and (max-width: 768px) {
                font-size: 13/320 * 100vw;
            }
        }
    }
}
