.career-detail-view {
    padding-top: 213/768 * 100vw;
    padding-right: 81/768 * 100vw;
    padding-left: 81/768 * 100vw;

    .title {
        position: relative;

        margin-bottom: 28/768 * 100vw;
        padding-bottom: 24/768 * 100vw;

        color: #000000;

        font-family: 'Rubik', sans-serif;
        font-size: 50/768 * 100vw;
        font-weight: 500;
        line-height: 60/768 * 100vw;

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 134/768 * 100vw;
            height: 12/768 * 100vw;

            content: ' ';

            background-color: #ee0000;
        }
    }

    .location {
        margin-bottom: 110/768 * 100vw;

        color: rgba(0, 0, 0, 0.4);

        font-family: 'Rubik', sans-serif;
        font-size: 30/768 * 100vw;
        font-weight: 500;
        line-height: 36/768 * 100vw;
    }

    .desc-title {
        margin-bottom: 34/768 * 100vw;

        color: #000000;

        font-family: 'Rubik', sans-serif;
        font-size: 36/768 * 100vw;
        font-weight: bold;
        line-height: 43/768 * 100vw;
    }

    .desc-content {
        margin-bottom: 34/768 * 100vw;

        white-space: pre-wrap;

        color: #000000;

        font-family: 'Rubik', sans-serif;
        font-size: 36/768 * 100vw;
        line-height: 52/768 * 100vw;
    }

    .button {
        margin-top: 94/768 * 100vw;
        margin-bottom: 220/768 * 100vw;
        padding: 33/768 * 100vw 92/768 * 100vw;

        font-size: 29/768 * 100vw;
        line-height: 34/768 * 100vw;
    }
}
