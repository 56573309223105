.about-content-2-2-view {
    .content {
        position: absolute;
        top: 176px;
        left: 4.65%;
        text-align: center;

        @media screen and (max-width: 768px) {
            left: 50%;
            top: 62/320 * 100vw;
            transform: translateX(-50%);
            text-align: center;
        }

        .title {
            color: #ffffff;
            span {
                color: #ec658c;
            }
            @media screen and (max-width: 768px) {
                width: 266/320 * 100vw;
            }
        }
        button {
            // background: #ec658c;
            font-weight: 600;
            color: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            min-width: 5.3125vw;
            margin: 0;
            padding: 0.8594vw 1.2375vw;
            cursor: pointer;
            -webkit-user-select: none;
            user-select: none;
            text-align: center;
            border: none;
            border-radius: 0.3734vw;
            background: linear-gradient(130deg, #e7678b 0%, #ec658c 100%);
            margin-top: 1.6vw;
            @media screen and (max-width: 768px) {
                margin-top: 3.6vw;
                padding: 3.6875vw 3.42708333vw;
            }
        }
    }
}
