.about-content-3-view {
    .content {
        position: absolute;
        top: 296px;
        left: 54.65%;

        @media screen and (max-width: 768px) {
            left: 50%;
            top: 82/320 * 100vw;
            transform: translateX(-50%);
            text-align: center;
        }

        .title {
            color: #ffffff;
        }

        .desc {
            width: 664px;
            color: #ffffff;

            @media screen and (max-width: 768px) {
                width: 266/320 * 100vw;
            }
        }
    }
}
