.shadow-card {
    border-radius: 20/2560 * 100vw;
    box-shadow: 0 16px 10px 0 rgba(219, 222, 234, 0.95);

    .shadow-card-inner {
        height: 100%;

        border-radius: 20/2560 * 100vw;
        box-shadow: 0 7px 0 0 #f0f2f8;
    }
}
