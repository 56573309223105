.contact-content-2-view {
    padding: 200px 0 344px 0;

    background-color: rgba(246, 246, 251, 0.7);

    .contact-card {
        position: relative;

        width: 1504px;
        height: 1808px;
        margin: 0 auto;

        @media screen and (max-width: 768px) {
            width: 271/320 * 100vw;
            height: 800/320 * 100vw;
        }

        .background-image {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            img {
                display: block;

                width: 100%;

                object-fit: cover;

                @media screen and (max-width: 768px) {
                    height: 100%;
                }
            }
        }

        .top-left-img {
            position: absolute;

            width: 240px;

            transform: translate(-20%, -18%);

            @media screen and (max-width: 768px) {
                width: 99/320 * 100vw;
            }

            img {
                display: block;

                width: 100%;
            }
        }

        .top-right-img {
            position: absolute;
            top: 0;
            right: 0;

            width: 440px;

            transform: translate(14%, -24%);

            @media screen and (max-width: 768px) {
                width: 185/320 * 100vw;
            }

            img {
                display: block;

                width: 100%;
            }
        }

        .bottom-right-img {
            position: absolute;
            right: 0;
            bottom: 0;

            width: 272px;

            transform: translate(30%, 16%);

            @media screen and (max-width: 768px) {
                width: 112/320 * 100vw;
            }

            img {
                display: block;

                width: 100%;
            }
        }

        .content {
            position: absolute;
            z-index: 2;
            top: 224px;
            left: 50%;

            display: flex;
            flex-direction: column;

            width: 50%;

            transform: translateX(-50%);

            @media screen and (max-width: 768px) {
                top: 138/320 * 100vw;

                width: 68%;
            }

            .MuiFormLabel-root {
                font-size: 1em;
            }

            .MuiInputLabel-formControl {
                transform: translate(0, 36px) scale(1);
            }

            .MuiInputLabel-shrink {
                transform: translate(0, 1.5px) scale(0.75);
            }

            .MuiInputBase-root {
                font-size: 1em;
            }

            .MuiInputBase-input {
                padding: 0.5em 0 0.5em;
            }

            label + .MuiInput-formControl {
                margin-top: 0.8em;
            }

            .title {
                margin-bottom: 72px;

                color: #000000;

                font-family: 'Rubik', sans-serif;
                font-size: 45px;
                font-weight: 500;
                line-height: 1;

                @media screen and (max-width: 768px) {
                    margin-bottom: 30/320 * 100vw;

                    font-size: 19/320 * 100vw;
                }
            }

            .input-item {
                margin-bottom: 56px;

                font-size: 24px;

                @media screen and (max-width: 1281px) {
                    font-size: 12px;
                }

                @media screen and (max-width: 768px) {
                    margin-bottom: 20/320 * 100vw;

                    font-size: 12/320 * 100vw;
                }
            }

            .input-item:last-of-type {
                margin-bottom: 200px;
            }

            .contact-submit-button {
                margin: 0 auto;
                padding: 0;
                padding: 32px 54px;

                cursor: pointer;
                user-select: 0;

                border: 0;
                border-radius: 10px;
                background: linear-gradient(
                    120deg,
                    rgba(255, 106, 76, 1) 0%,
                    rgba(220, 40, 38, 1) 100%
                );

                &.disabled {
                    cursor: not-allowed;

                    opacity: 0.6;
                }

                @media screen and (max-width: 768px) {
                    padding: 14/320 * 100vw 15/320 * 100vw;

                    border-radius: 5/320 * 100vw;
                }

                span {
                    color: #ffffff;

                    font-family: 'Rubik', sans-serif;
                    font-size: 34px;
                    font-weight: bold;

                    @media screen and (max-width: 768px) {
                        font-size: 12/320 * 100vw;
                    }
                }
            }
        }
    }
}
