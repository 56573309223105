@import url(../fonts/index.css);

* {
    box-sizing: border-box;

    outline: none;

    -webkit-tap-highlight-color: transparent;
}

html,
body {
    height: 100%;
    // 优先使用系统默认的界面字体，同时提供了一套利于屏显的备用字体库，
    // 来维护在不同平台以及浏览器的显示下，字体始终保持良好的易读性和可读性，
    // 体现了友好、稳定和专业的特性。

    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    // 在中后台系统中，数字经常需要进行纵向对比展示，我们单独将数字的字体
    // font-variant-numeric 设置为 tabular-nums，使其为等宽字体。

    font-variant-numeric: tabular-nums;
    // 基于电脑显示器阅读距离（50 cm）以及最佳阅读角度（0.3）默认设置字体大小 14，
    // 以保证在多数常用显示器上的用户阅读效率最佳。
    // font-size: 12px;
    // line-height: 1.57;
    // 参考了 WCAG 的标准，将正文文本、标题和背景色之间保持在了 7:1 以上的 AAA 级对比度。
    // 默认颜色为 @Black 85%
    // color: #262626;
    // 以上规则参考网址
    // https://ant.design/docs/spec/font-cn
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
}

body {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

#root {
    height: 100%;
}

.habby-website {
    .default-view {
        overflow-x: hidden;
        overflow-y: auto;

        height: 100%;
    }
    h1 {
        margin-bottom: 32px;

        text-transform: uppercase;

        font-family: 'Rubik', sans-serif;
        font-size: 60px;
        font-weight: 500;
        line-height: 1.2;

        @media screen and (max-width: 1281px) {
            margin-bottom: 16px;

            font-size: 32px;
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 18/320 * 100vw;

            font-size: 21/320 * 100vw;
        }
    }

    h2 {
        margin-bottom: 24px;

        text-transform: uppercase;

        font-family: 'Rubik', sans-serif;
        font-size: 50px;
        font-weight: 500;
        line-height: 1.2;

        @media screen and (max-width: 1281px) {
            margin-bottom: 13px;

            font-size: 26px;
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 15/320 * 100vw;

            font-size: 19/320 * 100vw;
        }
    }

    h3 {
        font-family: 'Rubik', sans-serif;
        font-size: 36px;
        font-weight: 500;
        line-height: 1.2;

        @media screen and (max-width: 1281px) {
            font-size: 20px;
        }

        @media screen and (max-width: 768px) {
            font-size: 17/320 * 100vw;
        }
    }

    h4 {
        font-family: 'Rubik', sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.2;

        @media screen and (max-width: 1281px) {
            font-size: 14px;
        }

        @media screen and (max-width: 768px) {
            font-size: 11/320 * 100vw;
        }
    }

    h5 {
        font-family: 'Rubik', sans-serif;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.2;

        @media screen and (max-width: 1281px) {
            font-size: 12px;
        }

        @media screen and (max-width: 768px) {
            font-size: 9/320 * 100vw;
        }
    }

    p {
        margin-bottom: 72px;

        font-family: 'Rubik', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 1.7;

        @media screen and (max-width: 1281px) {
            margin-bottom: 36px;

            font-size: 12px;
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 28/320 * 100vw;

            font-size: 13/320 * 100vw;
        }
    }
}
